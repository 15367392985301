import React from 'react';
import ReactDOM from "react-dom";
import './blog-post.scss';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  IBlogPostPreview,
  IBlogPostPreviewNode,
} from '../queries-intefaces/posts';
import { IMenuSection } from '../models/imenu-section';
import { Helmet } from 'react-helmet';
// import Img from 'gatsby-image';
import { Link } from 'react-scroll';
import loadable from '@loadable/component';

//import { CountryJson } from "../data/countries/countriesJson.json";

export interface IPostMenu {
  items: Array<IMenuSection>;
}

export interface IBlogPostState {
  itemSelected: number;
  blogPosts?: Array<IBlogPostPreviewNode>;
}

export interface IBlogPostTemplate {
  location: string;
}

class BlogPostTemplate extends React.Component<
IPostMenu,
IBlogPostState,
IBlogPostTemplate> {
  constructor(props) {
    super(props);

    this.state = {
      itemSelected: 0
    };
  }

  convert(num) {
    if(num < 1){ return "";}
    if(num >= 40){ return "XL" + this.convert(num - 40);}
    if(num >= 10){ return "X" + this.convert(num - 10);}
    if(num >= 9){ return "IX" + this.convert(num - 9);}
    if(num >= 5){ return "V" + this.convert(num - 5);}
    if(num >= 4){ return "IV" + this.convert(num - 4);}
    if(num >= 1){ return "I" + this.convert(num - 1);}
  }

  getChapterNumber(num){
    return (<p className="name">Chapter {this.convert(num)}</p>)
  }

  goToChapter(num){
    let chapter = document.getElementById(num)?.offsetTop - 100;
    window.scrollTo(0, chapter);
  }

  selectItemMenu(idx: number){
    this.setState({ itemSelected : idx });
  }

  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const ProductSection = loadable(() => import ('./product-section'));
    const WalletSection = loadable(() => import ('./wallet-section'));
    const LinksSection = loadable(() => import ('./links-section'));
    const HomePostsSection = loadable(() => import ('../components/home/home-posts-section/home-posts-section'));
    const HomeLineButtons = loadable(() => import ('../components/home/home-line-buttons/home-line-buttons'));

    const post = get(this.props, 'data.contentfulBlogPost');
    const blogPosts = get(this.props, 'data.allContentfulBlogPost.edges');
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    const productOffer = post?.productOffer;
    const walletOffer = post?.walletOffer;
    let blogPostsRelated: any =[];
    blogPosts?.map(x => {
      x.node.tags.map(y => {
        post?.tags.map(z => {
          if (z.code==y.code && x.node.title != post?.title){
            blogPostsRelated.push(x.node);
          }
        })
      })
    });
    const keysA = JSON.parse(post.keyAspect['raw']);
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    let pathname: any = '';
    if (typeof window !== 'undefined') {
      pathname = window.location?.origin + window.location?.pathname;
    }

    return (
      <Layout className="violet-style" footer={footerData} header={headerData}>
         <div className="atm-menu">
          <div className="box">
            <ul className="d-flex container">
              {post.chapters.map((_, i) => {
                return (
                  <li key={i} className={(this.state.itemSelected == i+1 ? 'selected' : '' )}>
                    <a onClick={() => this.goToChapter('chapter-'+(i+1))}>{this.getChapterNumber(i+1)}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div style={{ background: '#fff' }} className="blog-post">
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div className="container mb-5">
            <div className="hero">
              <img
                src={post.heroImage.fluid.src}
                alt=""
                className="image-blog"
              />
            </div>
            <div className='article-container' id="article-container">
              <h1 className="title-blog">{post.title}</h1>
              <div
                className="description-blog"
                dangerouslySetInnerHTML={{
                  __html: post.description.childMarkdownRemark.html,
                }}
              ></div>
              <div className="date-author">
                <div className="date">
                  <img className="icon-calendar" src="/icons/calendar.svg" />
                  <span>{post.publishDate}</span>
                </div>
                <div className="author">
                  <img className="icon-pencil" src="/icons/pencil.svg" />
                  <span>{post.author.name}</span>
                </div>
              </div>
              <div className="key-aspects">
                <div className="top-key-aspects">
                  <div className="top-key">
                    <img className="icon-keys" src="/icons/icon_keys.svg" />
                    <span>Key Aspects</span>
                  </div>
                </div>
                <div className="box-key-aspects">
                  <div className="content-box">
                    {
                      keysA.content.map((rtNode, i) => {
                          return (
                              <p key={i}>{rtNode.content[0].value}</p>
                          )
                      })
                    }
                  </div>
                </div>
              </div>
              <LinksSection data={post} />
              <div className="table-content">
                <h4>Table of Content</h4>
                <div className="chapters">
                  {post.chapters.map(( _ , i ) => {
                    return (
                      <a onClick={() => this.goToChapter('chapter-'+(i+1))} className="chapter" key={i}>
                        <div className="box-chapter">
                          <h1 className="number">{i+1}</h1>
                          {this.getChapterNumber(i+1)}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="chapter-container">
                {post.chapters.map(( chapter, i ) => {
                  return (
                    <div className='chapter-body' id={'chapter-'+(i+1)} key={i}>
                      <h3>{chapter.title}</h3>
                      <p className="content"  dangerouslySetInnerHTML={{
                        __html: chapter.body.childMarkdownRemark.html,
                      }}></p>
                      <div id='link-offer'>
                        { chapter.productOffer != null &&
                          <ProductSection data={chapter.productOffer}/>
                        }
                        { chapter.walletOffer != null &&
                          <WalletSection data={chapter.walletOffer}/>
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="chapter-container"
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html,
                }}
              ></div>
              <div id='link-offer'>
              { productOffer &&
                <ProductSection data={productOffer}/>
              }
              { walletOffer &&
                <WalletSection data={walletOffer}/>
              }
              </div>
              <div className="footer-signature">
                <div className="date-author signature">
                  <div className="date">
                    <img className="icon-calendar" src="/icons/calendar.svg" />
                    <span>{post.publishDate}</span>
                  </div>
                  <div className="author">
                    <img className="icon-pencil" src="/icons/pencil.svg" />
                    <span>{post.author.name}</span>
                  </div>
                </div>
                <div className="tags">
                  {post.tags.map((tag, i) => {
                    return (
                      <div className="tag" key={i}>
                        {tag.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        { blogPostsRelated.length > 0 &&
          <>
          <HomeLineButtons textLeft="Related articles" />
          <HomePostsSection posts={blogPostsRelated} key="related" />
          </>
        }
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      author {
        name
      }
      tags {
        code
        name
      }
      keyAspect {
        raw
      }
      chapters{
        title
        body {
          childMarkdownRemark {
            html
          }
        }
        walletOffer{
          title
          image {
            fluid(maxWidth: 1180, background: "rgb:000000") {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          subTitle
          facebook
          twitter
          founded
          devices
          description {
            childMarkdownRemark {
              html
            }
          }
          buttonText
          buttonLink
          supportedCurrencies{
            name
            icon {
              fluid(maxWidth: 400, background: "rgb:000000") {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          }
          founders{
            name
            link
          }
        }
        productOffer{
          title
          image {
            fluid(maxWidth: 1180, background: "rgb:000000") {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          buttonText
          buttonLink
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          links {
            text
            link
          }
        }
      }
      walletOffer{
        title
        image {
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        subTitle
        facebook
        twitter
        founded
        devices
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonLink
        supportedCurrencies{
          name
          icon {
            fluid(maxWidth: 400, background: "rgb:000000") {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
        founders{
          name
          link
        }
      }
      productOffer{
        title
        image {
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        buttonText
        buttonLink
        description {
          childMarkdownRemark {
            html
          }
        }
        specifications {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
        links {
          text
          link
        }
      }
      textToShareLinks
    }
    allContentfulBlogPost (sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags {
            code
            name
          }
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              src
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          textToShareLinks
        }
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulFounder {
      nodes {
        link
        name
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
